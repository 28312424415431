import React, { FC } from 'react';
import { useNavigate } from 'react-router-dom';
import { IBlacklistedProduct, IProductCategory } from '../../../admin/models';
import { ProgressCategories } from '../../../planning/components';
import { ShipmentStatus } from '../../../planning/enums';
import { useSaveShipment } from '../../../planning/hooks';
import { IDashboardShipment } from '../../../planning/models';
import { ListModel, Loading, Widget } from '../../../shared';

interface Props {
    shipment: IDashboardShipment;
    usedCategories?: IProductCategory[];
    blacklistedCategories?: ListModel<IBlacklistedProduct>;
}
export const DashboardItem: FC<Props> = ({ shipment, usedCategories, blacklistedCategories }) => {
    const navigate = useNavigate();

    const { mutateAsync: saveShipment, isPending: isPendingSave } = useSaveShipment();

    const onShipmentClick = async () => {
        if (isPendingSave) return;
        if (shipment.shipmentId) {
            navigate(`/planning/shipments/${shipment.shipmentId}/detail`, {
                state: '/planning/dashboard',
            });
        } else {
            const result = await saveShipment({
                item: {
                    receiverId: shipment.receiver.id,
                    shipmentDate: shipment.shipmentDate,
                    status: ShipmentStatus.DRAFT,
                },
            });
            navigate(`/planning/shipments/${result.id}/detail`, {
                state: '/planning/dashboard',
            });
        }
    };

    return (
        <Widget sx={{ height: 200 }} onClick={onShipmentClick}>
            {isPendingSave ? (
                <Loading />
            ) : (
                <ProgressCategories
                    organisation={shipment.receiver}
                    shipmentDate={shipment.shipmentDate}
                    shipmentStatus={shipment.status}
                    shipmentAmountOfDrafts={shipment.amountOfDrafts}
                    progresses={shipment.progresses}
                    visibleCategories={usedCategories}
                    blacklistedCategories={
                        blacklistedCategories?.data?.filter(
                            (blacklisted) => blacklisted.organisationId === shipment.receiver.id,
                        ) || []
                    }
                />
            )}
        </Widget>
    );
};
