import { Button } from '@mui/material';
import { GridCellParams, GridColDef, GridColumnVisibilityModel } from '@mui/x-data-grid';
import { FC, useCallback, useEffect, useMemo, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { Link, useNavigate } from 'react-router-dom';
import { IOperator, IStockLocation } from '../../../admin/models';
import {
    formatDateTimeString,
    IProduct,
    isBadRequest,
    mapDateStringToApiDate,
    mapDateToApiDate,
    MeasurementUnit,
    Page,
    PermissionKeys,
    RowActions,
    SortOrder,
    TableComponent,
    useHasPermission,
    useQueryParamsContext,
    WarningDialog,
} from '../../../shared';
import { StockMovementsFilter } from '../../components';
import { StockMovementType } from '../../enums';
import { useDeleteStockMovement, useStockMovementsList } from '../../hooks';

const today = new Date().toISOString();
export const StockMovementsPage: FC = () => {
    const { t } = useTranslation();
    const navigate = useNavigate();
    const { hasPermission } = useHasPermission();
    const hasWritePermission = hasPermission(PermissionKeys.STOCK_MOVEMENTS_WRITE);

    const {
        queryParams: { page, pageSize, filter, sort },
        setFilter,
        setPage,
        setPageSize,
        setSort,
    } = useQueryParamsContext('stock-movements', {
        page: 1,
        pageSize: 10,
        search: '',
        sort: [{ field: 'createdDate', sort: 'desc' }],
        filter: { date: today },
    });

    const { isPending, data } = useStockMovementsList(
        {
            page: page,
            pageSize: pageSize,
            sortBy: sort[0].field,
            sortOrder: sort[0].sort?.toUpperCase() as SortOrder,
            date: mapDateStringToApiDate(filter.date) || undefined,
            type: (filter.type as StockMovementType) || undefined,
            product: filter.product || undefined,
            productCode: filter.productCode || undefined,
            supplier: filter.supplier || undefined,
            organisation: filter.organisation || undefined,
            operator: filter.operator || undefined,
            stockLocation: filter.stockLocation || undefined,
            expirationDate: filter.expirationDate ? mapDateToApiDate(filter.expirationDate) || undefined : undefined,
        },
        { refetchInterval: 8000 },
    );

    const { mutateAsync: deleteStockMovement } = useDeleteStockMovement();
    const [showStockMovementInUse, setShowStockMovementInUse] = useState(false);
    const onDelete = useCallback(
        async (id: string) => {
            try {
                await deleteStockMovement(id);
            } catch (err) {
                if (isBadRequest(err)) return setShowStockMovementInUse(true);
                throw err;
            }
        },
        [deleteStockMovement],
    );

    const columns: GridColDef[] = [
        {
            field: 'createdDate',
            headerName: t('createdDate'),
            valueFormatter: ({ value }) => formatDateTimeString(value as string),
            minWidth: 150,
        },
        {
            field: 'type',
            headerName: t('type'),
            valueFormatter: ({ value }) => t(value as string),
            minWidth: 100,
        },
        {
            field: 'operator',
            headerName: t('operator'),
            valueFormatter: ({ value }) => (value as IOperator)?.name,
            minWidth: 150,
            sortable: false,
        },
        {
            field: 'supplier',
            headerName: t('supplier'),
            minWidth: 150,
            sortable: false,
            renderCell: ({ row }: GridCellParams) => (row.batch ? row.batch.supplier?.name : row.supplier?.name),
        },
        {
            field: 'batch',
            headerName: t('batch'),
            valueFormatter: ({ value }) => value?.referenceNumber,
            minWidth: 150,
            sortable: false,
        },
        {
            field: 'stockLocation',
            headerName: t('stockLocation'),
            valueFormatter: ({ value }) => (value as IStockLocation)?.name,
            minWidth: 150,
            sortable: false,
        },
        {
            field: 'organisation',
            headerName: t('organisation'),
            valueFormatter: ({ value }) => value?.name,
            minWidth: 150,
            sortable: false,
        },
        {
            field: 'product',
            headerName: t('product'),
            valueFormatter: ({ value }) => (value as IProduct)?.name,
            minWidth: 200,
            sortable: false,
        },
        {
            field: 'amount',
            headerName: t('amount'),
            minWidth: 100,
            renderCell: ({ row }: GridCellParams) => (
                <>
                    {row.amount}{' '}
                    {!row.product ? '' : row.product?.measurementUnit === MeasurementUnit.GRAM ? t('gram') : t('PIECE')}
                </>
            ),
            sortable: false,
        },
        {
            field: 'total_weight',
            headerName: t('totalWeight'),
            minWidth: 150,
            renderCell: ({ row }: GridCellParams) => <>{row.product && row.amount * (row.product.weight || 1)}</>,
        },
        {
            field: 'expirationDate',
            headerName: t('expirationDate'),
            minWidth: 130,
        },
        {
            field: ' ',
            headerName: t('actions'),
            hideable: false,
            minWidth: 100,
            align: 'right',
            headerAlign: 'center',
            sortable: false,
            renderCell: ({ row: { id } }: GridCellParams) => (
                <>
                    {hasWritePermission && (
                        <RowActions
                            onEdit={() => navigate(`/stock/movements/${id}/edit`)}
                            onDelete={() => onDelete(id)}
                            deleteWarningTitle={t('stockMovementDeleteWarningTitle')}
                            deleteWarningText={t('stockMovementDeleteWarningText')}
                        />
                    )}
                </>
            ),
        },
    ];

    const columnNames = useMemo(() => {
        return [
            'createdDate',
            'type',
            'operator',
            'supplier',
            'batch',
            'stockLocation',
            'organisation',
            'product',
            'amount',
            'total_weight',
            'expirationDate',
        ];
    }, []);

    const getInitialValues = () => {
        const initialValues = columnNames.reduce(
            (obj, key) =>
                Object.assign(obj, {
                    [key]: localStorage.getItem(key) !== null ? localStorage.getItem(key) === 'true' : true,
                }),
            {},
        );

        return initialValues as GridColumnVisibilityModel;
    };

    const [visibleColumns, setVisibleColumns] = useState<GridColumnVisibilityModel>(getInitialValues());

    useEffect(() => {
        columnNames.forEach((field) =>
            localStorage.setItem(
                field,
                Object.keys(visibleColumns)?.length > 0 ? visibleColumns[field]?.toString() : 'true',
            ),
        );
    }, [columnNames, visibleColumns]);

    return (
        <Page
            title={t('stockMovements')}
            filter={<StockMovementsFilter filter={filter} onChange={setFilter} />}
            actions={[
                hasWritePermission && (
                    <Button
                        component={Link}
                        to="/stock/movements/new"
                        color="primary"
                        variant="contained"
                        data-testid="new-stock-movement"
                    >
                        {t('newStockMovement')}
                    </Button>
                ),
            ]}
        >
            <TableComponent
                rows={data?.data || []}
                columns={columns}
                rowCount={data?.pagination.size || 0}
                paginationModel={{ page: page ? page - 1 : 0, pageSize: pageSize || 10 }}
                onPaginationModelChange={(value) => {
                    setPage(value?.page + 1);
                    setPageSize(value?.pageSize || 5);
                }}
                loading={isPending}
                sortModel={sort}
                onSortModelChange={setSort}
                defaultSortModel={[{ field: 'createdDate', sort: 'desc' }]}
                onRowClick={(row) => hasWritePermission && navigate(`/stock/movements/${row.id}/edit`)}
                disableColumnMenu={false}
                localeText={{
                    columnMenuHideColumn: t('columnMenuHideColumn'),
                    columnMenuManageColumns: t('columnMenuManageColumns'),
                    columnMenuSortAsc: t('columnMenuSortAsc'),
                    columnMenuSortDesc: t('columnMenuSortDesc'),
                    columnsPanelTextFieldLabel: t('columnsPanelTextFieldLabel'),
                    columnsPanelTextFieldPlaceholder: '',
                    columnsPanelShowAllButton: t('columnsPanelShowAllButton'),
                    columnsPanelHideAllButton: t('columnsPanelHideAllButton'),
                }}
                initialState={visibleColumns}
                columnVisibilityModel={visibleColumns}
                onColumnVisibilityModelChange={(model) => setVisibleColumns(model)}
            />

            <WarningDialog
                open={showStockMovementInUse}
                onClose={() => setShowStockMovementInUse(false)}
                title={t('stockMovementInUseTitle')}
                text={t('stockMovementInUseDescription')}
            />
        </Page>
    );
};
