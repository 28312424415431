import { Check, ChevronLeft } from '@mui/icons-material';
import { Alert, Button, Checkbox, FormControlLabel, Grid, Stack } from '@mui/material';
import React, { FC, useCallback, useEffect, useMemo, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useNavigate, useParams } from 'react-router-dom';
import { ContactType, IParams, Section, Widget } from '../../../shared';
import { Asset, UploadShipmentAssetDialog } from '../../components';
import { ShipmentMailStatus, ShipmentStatus } from '../../enums';
import { useDeleteShipmentAsset, useSaveShipment, useShipment, useUploadShipmentAsset } from '../../hooks';

export const ShipmentAttachmentsPage: FC = () => {
    const { t } = useTranslation();
    const { id } = useParams<keyof IParams>() as IParams;
    const navigate = useNavigate();
    const [showUploadModal, setShowUploadModal] = useState(false);
    const [showUploadError, setShowUploadError] = useState(false);
    const [sendShipmentMail, setSendShipmentMail] = useState(false);

    const { mutateAsync: saveShipment, isPending: isPendingSave } = useSaveShipment();
    const { data: shipment } = useShipment(id);
    const { mutateAsync: uploadAsset, isPending: uploading } = useUploadShipmentAsset();
    const { mutateAsync: deleteAsset } = useDeleteShipmentAsset();

    const emailConfigured = useMemo(() => {
        return shipment?.receiver?.contacts
            ? shipment?.receiver.contacts?.filter((contact) => contact.type === ContactType.Email)?.length > 0
            : false;
    }, [shipment]);

    const onComplete = async () => {
        if (shipment) {
            await saveShipment({
                id,
                item: {
                    ...shipment,
                    receiverId: shipment.receiver?.id,
                    status: ShipmentStatus.COMPLETED,
                    sendShipmentMail,
                },
            });
            navigate(`/planning/shipments/${shipment.id}/detail`);
        }
    };

    const onUploadAsset = useCallback(
        async (file: File) => {
            if (file) {
                try {
                    await uploadAsset({ id, file });
                    setShowUploadModal(false);
                    setShowUploadError(false);
                } catch (err) {
                    setShowUploadError(true);
                }
            }
        },
        [id, uploadAsset],
    );

    const onDeleteAsset = useCallback(
        async (assetId: string) => {
            await deleteAsset({ id, assetId });
        },
        [deleteAsset, id],
    );

    const onCloseUpload = useCallback(() => {
        setShowUploadModal(false);
        setShowUploadError(false);
    }, []);

    useEffect(() => {
        if (emailConfigured) {
            // Only set default to true when mail is not sent yet
            setSendShipmentMail(shipment?.mailStatus !== ShipmentMailStatus.SENT);
        }
    }, [shipment, emailConfigured]);

    return (
        <Widget
            footer={
                <Stack direction="row" justifyContent="space-between">
                    <Button startIcon={<ChevronLeft />} onClick={() => navigate(`/planning/shipments/${id}/content`)}>
                        {t('previous')}
                    </Button>
                    <Button
                        endIcon={<Check />}
                        variant="contained"
                        color="primary"
                        onClick={onComplete}
                        disabled={isPendingSave}
                    >
                        {t('complete')}
                    </Button>
                </Stack>
            }
        >
            <Stack spacing={2}>
                <Section
                    title={t('attachments')}
                    footer={<Button onClick={() => setShowUploadModal(true)}>+ {t('addAttachment')}</Button>}
                >
                    <Grid container spacing={2} alignItems="stretch">
                        <Grid item xs={12} sm={6} md={6} lg={4}>
                            <Asset
                                asset={{
                                    id: 'shipmentDocument',
                                    fileName: t('shipmentDocument'),
                                    mimeType: 'application/pdf',
                                    downloadUrl: `/shipments/${id}/document`,
                                }}
                            />
                        </Grid>
                        {shipment?.assets.map((asset) => (
                            <Grid item xs={12} sm={6} md={6} lg={4} key={asset.id}>
                                <Asset asset={asset} onDelete={onDeleteAsset} />
                            </Grid>
                        ))}
                    </Grid>
                    <UploadShipmentAssetDialog
                        open={showUploadModal}
                        onClose={onCloseUpload}
                        onUpload={onUploadAsset}
                        uploading={uploading}
                        failed={showUploadError}
                    />
                </Section>
                <Section title={t('shipmentMail')}>
                    <FormControlLabel
                        label={t('sendShipmentMail') as string}
                        control={
                            <Checkbox
                                onChange={() => setSendShipmentMail(!sendShipmentMail)}
                                checked={sendShipmentMail}
                                disabled={!emailConfigured}
                            />
                        }
                    />
                    {!emailConfigured && <Alert severity="info">{t('emailNotConfigured')}</Alert>}
                </Section>
            </Stack>
        </Widget>
    );
};
