import { yupResolver } from '@hookform/resolvers/yup';
import { Fullscreen } from '@mui/icons-material';
import { Button, Grid, IconButton, Stack } from '@mui/material';
import { addDays, differenceInCalendarDays, isValid } from 'date-fns';
import React, { FC, useEffect, useMemo, useState } from 'react';
import { FormProvider, useForm } from 'react-hook-form';
import { useTranslation } from 'react-i18next';
import { date, object } from 'yup';
import { useBlackListedProductsList } from '../../../admin/hooks';
import { DashboardItem } from '../../../main/components/dashboard-item/dashboard-item.component';
import {
    ControlledDatePicker,
    FormGrid,
    mapDateToApiDate,
    mapDateToRequiredApiDate,
    Page,
    useQueryParamsContext,
} from '../../../shared';
import { OrganisationProgressLegend } from '../../components';
import { useAllCategories, useShipmentDashboard } from '../../hooks';

const fullScreenStyle = {
    position: 'fixed',
    top: 0,
    left: 0,
    bottom: 0,
    right: 0,
    backgroundColor: 'white',
    zIndex: 10000,
    overflow: 'auto',
    p: 3,
};

interface FormData {
    startDate: Date;
    endDate: Date;
}

const useSchema = () => {
    const { t } = useTranslation();

    return object().shape({
        startDate: date().typeError(t('invalidDate')).required(),
        endDate: date()
            .typeError(t('invalidDate'))
            .required()
            .test({
                name: 'range',
                message: t('dashboardRangeToLarge'),
                test: (value, context) => differenceInCalendarDays(value, context.parent.startDate) <= 7,
            }),
    });
};

export const DashboardPage: FC = () => {
    const { t } = useTranslation();
    const [fullScreen, setFullScreen] = useState(false);

    const nextWorkingDay = () => {
        const nextDay = addDays(new Date(), 1);
        if (nextDay.getDay() === 6) return addDays(new Date(), 3); //saturday
        if (nextDay.getDay() === 0) return addDays(new Date(), 2); //sunday

        return nextDay;
    };

    const {
        queryParams: { filter },
        setFilter,
    } = useQueryParamsContext('dashboard', {
        filter: {
            startDate: mapDateToRequiredApiDate(nextWorkingDay()),
            endDate: mapDateToRequiredApiDate(nextWorkingDay()),
        },
    });

    const { data: shipmentDashboard } = useShipmentDashboard(
        { startDate: filter.startDate, endDate: filter.endDate },
        5000,
    );
    const categories = useAllCategories();
    const { data: blacklistedCategories } = useBlackListedProductsList({
        hasOnlyCategory: true,
        startDate: mapDateToApiDate(filter.startDate) || undefined,
        endDate: mapDateToApiDate(filter.endDate) || undefined,
        pageSize: 1000,
    });

    const usedCategories = useMemo(
        () =>
            categories.filter(
                (category) =>
                    shipmentDashboard?.shipments.some((shipment) =>
                        shipment.progresses.some(({ category: id }) => id === category.id),
                    ),
            ),
        [categories, shipmentDashboard],
    );

    const shipments = useMemo(
        () =>
            shipmentDashboard?.shipments?.sort((a, b) =>
                a.receiver?.id === b.receiver?.id
                    ? a.shipmentDate.localeCompare(b.shipmentDate)
                    : a.receiver?.id.localeCompare(b.receiver?.id),
            ) || [],
        [shipmentDashboard?.shipments],
    );

    const schema = useSchema();
    const form = useForm<FormData>({
        resolver: yupResolver(schema),
        mode: 'onSubmit',
    });

    const watch = form.watch();

    useEffect(() => {
        if (!watch.startDate || !watch.endDate) {
            form.reset({ startDate: new Date(filter.startDate), endDate: new Date(filter.endDate) });
        }
    }, [form, filter, watch]);

    const onSubmit = async (item: FormData) => {
        setFilter({
            startDate: mapDateToRequiredApiDate(item.startDate),
            endDate: mapDateToRequiredApiDate(item.endDate),
        });
    };

    const showModifyButton = useMemo(() => {
        if (isValid(watch.startDate) && isValid(watch.endDate) && watch.startDate <= watch.endDate) {
            return (
                mapDateToRequiredApiDate(watch.startDate) !== filter.startDate ||
                mapDateToRequiredApiDate(watch.endDate) !== filter.endDate
            );
        }
        return false;
    }, [watch, filter]);

    return (
        <Page
            title={t('dashboard')}
            actions={
                <FormProvider {...form}>
                    <Grid item xs={12}>
                        <FormGrid alignItems="center">
                            <ControlledDatePicker name="startDate" label={t('startDate')} required size="small" />
                            <ControlledDatePicker
                                name="endDate"
                                label={t('endDate')}
                                required
                                size="small"
                                maxDate={addDays(watch.startDate, 7)}
                            />
                            {showModifyButton && (
                                <Button variant="contained" color="primary" onClick={form.handleSubmit(onSubmit)}>
                                    {t('modify')}
                                </Button>
                            )}
                        </FormGrid>
                    </Grid>
                </FormProvider>
            }
        >
            <Grid
                container
                spacing={2}
                sx={fullScreen ? fullScreenStyle : {}}
                alignItems="flex-start"
                alignContent="start"
            >
                <Grid item xs={12}>
                    <Stack direction="row" alignItems="center">
                        <OrganisationProgressLegend usedCategories={usedCategories} />
                        <IconButton onClick={() => setFullScreen(!fullScreen)}>
                            <Fullscreen />
                        </IconButton>
                    </Stack>
                </Grid>
                {shipments.map((shipment, index) => (
                    <Grid item xs={12} sm={4} key={index}>
                        <DashboardItem
                            shipment={shipment}
                            usedCategories={usedCategories}
                            blacklistedCategories={blacklistedCategories}
                        />
                    </Grid>
                ))}
            </Grid>
        </Page>
    );
};
